import { Auth } from "@aws-amplify/auth";
import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { baseUrl } from "../baseUrl";

export const privateApi = createApi({
  baseQuery: baseQuery({ baseUrl: `${baseUrl}/gc` }),
  endpoints: () => ({}),
  reducerPath: "privateApi",
  tagTypes: [],
});

type BaseQueryContext = { baseUrl: string };

function baseQuery(context: BaseQueryContext): BaseQueryFn<
  AxiosRequestConfig & {
    responseHandler?: (response: AxiosResponse) => unknown;
  }
> {
  return async (arg) => {
    try {
      const { responseHandler, url } = arg;
      const currentSession = await Auth.currentSession();
      const response = await axios({
        ...arg,
        headers: { Authorization: currentSession.getIdToken().getJwtToken() },
        url: `${context.baseUrl}${url}`,
        withCredentials: true,
      });
      return {
        data: responseHandler ? responseHandler(response) : response.data,
      };
    } catch (axiosError) {
      const error = axiosError as AxiosError;
      return {
        error: {
          data: error.response?.data ?? error.message,
          status: error.response?.status ?? 500,
        },
      };
    }
  };
}
